<template>
  <HomeContent></HomeContent>
  <SideContent></SideContent>
  <div class="container">
    <div class="row">
      <div class="col-md-9 mx-auto">
        <div class="about mt-5 mb-5">
          <h1 class="mt-5 text-center">公司簡介</h1>
          <h2 class="mt-3 text-center" :class="deviceType == 'pc' ? 'mb-5' : ''"></h2>

          <div class="row" :class="deviceType == 'pc' ? 'mt-5' : ''">
            <div class="col-md-6 content mb-5">
              <h3>宏順馳通運</h3>
              <div>
                <hr>
              </div>
              <h4 class="mb-4">
                全國配送/包裝作業<br>
                冷鏈配送/冷凍倉儲<br>
                專車服務/GPS追蹤<br>
                代收貨款/車輛維修<br>
                專營 大量貨件包裝/全國多點配送<br>
                業務洽詢 請電(02)2311-3505
              </h4>
              <h5>
                四溫層配送<br>
                冷凍：零下20度<br>
                冷藏：5度C<br>
                空調：18度<br>
                常溫：25度
              </h5>
              <router-link to="/page/aboutus" class="btn btn-primary btn-sm mt-3">了解更多</router-link>
            </div>
            <div class="col-md-6">
              <img src="/aboutus-2.jpg">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <SearchContent>

  </SearchContent>


  <LinksContent></LinksContent>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  name: "HomeView",
  components: {
    HomeContent: defineAsyncComponent(() =>
      import("@/components/HomeContent.vue")
    ),
    SideContent: defineAsyncComponent(() =>
      import("@/components/SideContent.vue")
    ),
    // NewsContent: defineAsyncComponent(() =>
    //   import("@/components/NewsContent.vue")
    // ),
    LinksContent: defineAsyncComponent(() =>
      import("@/components/LinksContent.vue")
    ),
    SearchContent: defineAsyncComponent(() =>
      import("@/components/SearchContent.vue")
    ),
  },
  data() {
    return {
      deviceType: "pc",
    };
  },
  mounted() {
    this.deviceType = this.$store.state.deviceType;
  },
};
</script>
<style>
.about h1 {
  color: #e12b34;
  padding: 0;
  margin: 0;
  font-weight: normal;
}

.about h2 {
  color: #222322;
  font-size: 20px;
  padding: 0;
  margin: 0;
  font-weight: normal;
}

.about img {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
}

.about h3 {
  /* font-size: 20px; */
  /* font-weight: normal; */
}

.about hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 3px solid;
  opacity: 1;
  width: 50px;
  color: #e12b34;
}

.about .content {
  line-height: 40px;
}

.about .content button {
  padding: 5px 20px;
  border-radius: 20px;
}

@media only screen and (max-width: 1440px) {}

@media only screen and (max-width: 1366px) {}

@media only screen and (max-width: 1280px) {}

@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 992px) {
  .about img {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
  }

  .about h4,
  .about h5 {
    font-size: 18px;
  }

  .about .content {
    line-height: 10px;
  }

}
</style>
